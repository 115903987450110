import { CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "inter-ui/inter.css";
import { BrowserRouter } from "react-router-dom";

import { ErrorBoundary } from "src/components/Components_Common/ErrorBoundary/ErrorBoundary";
import { LanguagesProvider } from "src/contexts/languages";
import { ThemeProvider } from "src/contexts/theme";
import { ToastProvider } from "src/contexts/toasts";
import { UserProvider } from "src/contexts/user";
import { Router } from "src/router";

import { LocalizationWrapper } from "./configurations/LocalizationWrapper";

import "src/index.css";

console.log(`--- APP VERSION : ${__APP_VERSION__}`);
console.log(`--- APP VITE_INTL_DEBUG : `, import.meta.env.VITE_INTL_DEBUG);

///////////////////////////////
// React Query              ///
///////////////////////////////

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
});

///////////////////////////////
// Sentry                   ///
///////////////////////////////

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
});

///////////////////////////////
// MUI PRO LICENSE          ///
///////////////////////////////
LicenseInfo.setLicenseKey(
    "520d15ad231b43c10c4ee365251a0ce7Tz0xMDM5NTMsRT0xNzY1MzY5MzQ4MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y",
);

///////////////////////////////
// Render                   ///
///////////////////////////////

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ErrorBoundary
            fallbackText={
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <h1>Something went wrong.</h1>
                    <h3> Please, reload the page.</h3>
                </div>
            }
        >
            <ThemeProvider>
                <>
                    <CssBaseline enableColorScheme />
                    {/* Contextes Packages */}
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            {/* Contextes TEAMOTY */}
                            <LanguagesProvider>
                                <ToastProvider>
                                    <UserProvider>
                                        <LocalizationWrapper>
                                            {/* Application */}
                                            <Router />
                                        </LocalizationWrapper>
                                    </UserProvider>
                                </ToastProvider>
                            </LanguagesProvider>
                            {/* Affichage du debugger de REACT QUERY */}
                            {import.meta.env.VITE_SENTRY_ENVIRONMENT !==
                                "production" && (
                                <ReactQueryDevtools initialIsOpen={false} />
                            )}
                        </BrowserRouter>
                    </QueryClientProvider>
                </>
            </ThemeProvider>
        </ErrorBoundary>
    </React.StrictMode>,
);
