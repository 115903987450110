import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import { ReactNode } from "react";
import {
    FallbackProps,
    ErrorBoundary as ReactErrorBoundary,
} from "react-error-boundary";

import { useCoreIntl } from "src/hooks/useCoreIntl";

const Fallback = ({ error: _error, resetErrorBoundary }: FallbackProps) => {
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Errors");
    const { formatMessageWithPartialKey: fmtAction } = useCoreIntl("Actions");

    return (
        <Stack alignItems={"center"} justifyContent={"center"} flexGrow={1}>
            <Typography variant={"h1"}>{fmt("GenericError")}</Typography>
            <Button variant={"outlined"} onClick={resetErrorBoundary}>
                {fmtAction("Reset")}
            </Button>
        </Stack>
    );
};

export const ErrorBoundary = ({
    children,
    fallbackText,
}: {
    children: ReactNode;
    fallbackText?: ReactNode;
}) => {
    const props = {
        ...(fallbackText
            ? { fallback: fallbackText }
            : { FallbackComponent: Fallback }),
    };
    return <ReactErrorBoundary {...props}>{children}</ReactErrorBoundary>;
};
